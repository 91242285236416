import axios from "axios";
import Vue from "vue";
import router from "../router";

const baseURL = process.env.VUE_APP_API_URL;

const axiosIns = axios.create({
  baseURL,
});

axiosIns.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    const accessToken = localStorage.getItem("accessToken");

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => Promise.reject(error)
);

axiosIns.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalReq = error.config;

    if (status == 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userData");
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("userData");
      router.push({ name: "login" });
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
